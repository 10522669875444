<template>
  <v-list-item dense>
    <v-list-item-content>
      <v-list-item-title class="primary--text" style="pointer-events: none;">
        {{ title }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'DropdownMenuItemHeader',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>
